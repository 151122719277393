<template>
  <!-- <modal name="brainstorm-edit-modal" @closed="closeModal(() => previewURL = '')" :height="'auto'" :classes="'bg-primary-three'"> -->
    <div class="p-10">
      <div class="flex justify-between pb-5 i-border-b-1 border-primary-one">
        <h3 class="font-poppins text-primary-one fs-26">Edit Brainstorm </h3>
      </div>

      <div class="i-mt-30">
        <div>
          <h4 class="font-poppins text-secondary-one fs-14">Title</h4>
          <input type="text"
                 class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-primary-one rounded"
                 v-model="brainstormData.title"
                 :placeholder="brainstormPlaceholder.title"
                 @click="brainstormPlaceholder.title = ''"
                 v-click-outside="outsideTitleInput">
        </div>
        <div class="i-mt-30">
          <h4 class="font-poppins text-secondary-one fs-14">Description</h4>
          <textarea class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 py-2 h-6r i-border-1 border-solid border-primary-one rounded bld-content-area"
                    v-model="brainstormData.description"
                    :placeholder="brainstormPlaceholder.description"
                    @click="brainstormPlaceholder.description = ''"
                    v-click-outside="outsideDescription"></textarea>
        </div>
        <div class="mt-5">
          <h4 class="font-poppins text-secondary-one fs-14">Scenario</h4>
          <select class="bg-transparent text-secondary-two fs-14 w-full i-border-1 border-primary-one mt-2 py-2 rounded px-4" v-model="brainstormData.scenario_id" placeholder="Select scenario">
<!--            <option value="">Select Scenario</option>-->
            <option v-for="(scenario, sIndex) in scenarios" :key="sIndex" :value="scenario.id">{{ scenario.title }}</option>
          </select>
        </div>
        <div class="i-mt-30 flex gap-6 items-center">
            <label class=" flex gap-3 items-center cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3zM8 13h2.55v3h2.9v-3H16l-4-4z"/></svg>
                <span>File Upload</span>
                <input @input="(e)=>{onChange(e), fileUpload(file)}" hidden type="file" accept="image/x-png,image/jpeg" />
            </label>
            <img v-if="previewURL" class="w-10 h-8 object-cover" :src="(previewURL) ? previewURL : brainstormData?.file?.file_path" />
        </div>
        <div class="flex i-mt-30">
          <button class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one" @click="updateBrainstorm(() => previewURL = '')" :disabled="loading">Update</button>
          <button class="focus:outline-none fs-14 fw-600 font-poppins i-pl-35 i-pr-35 i-border-1 border-secondary-one ml-5 rounded text-primary-one h-10 w-40" @click="closeModal(() => previewURL = '')" :disabled="loading">Cancel</button>
        </div>
      </div>
    </div>
  <!-- </modal> -->
</template>

<script setup>
  import { useFile } from '@/composable/useFile.js'
  let { onChange, previewURL, file } = useFile()
</script>

<script>
import {mapGetters} from "vuex";

export default {
  name: "EditBrainstorm",
  props: ['dModal', 'currentModuleId', "selectedCardData"],
  created() {
    // this.$store.dispatch("programmatic/getModules", this.$route.params.id);
    this.$store.dispatch("programmatic/loadScenarios", this.$route.params.id);
  },
  data() {
    return {
      brainstormPlaceholder: {
        title: 'Type title here',
        description: 'Your description here'
      },
      brainstormData: {},
      formData: new FormData(),
    }
  },
  computed: {
    ...mapGetters({
      loading: "GET_LOADING_STATE",
      projectModules: "programmatic/projectModules",
      brainstorm: "brainstormv1/brainstorm",
      brainstorms: "brainstormv1/brainstorms",
      scenarios: 'programmatic/scenarios',
    }),
  },
  watch: {
    dModal: function (val) {
      if(val) {
        this.$modal.show('brainstorm-edit-modal');
      }
    }
  },
  mounted: function(){
    this.setEditableData(this.selectedCardData)
  },
  methods: {
    closeModal(callback = () => {}) {
      this.$modal.hide('brainstorm-edit-modal')
      this.$emit('close')
      callback()
    },
    outsideTitleInput() {
      this.brainstormPlaceholder.title = 'Type title here';
    },
    outsideDescription() {
      this.brainstormPlaceholder.description = 'Type description here';
    },
    checkExistence(moduleId) {
      let existence = this.brainstorms.find(item => item.module_id && parseInt(item.module_id) === parseInt(moduleId) &&
          parseInt(moduleId) !== parseInt(this.brainstorm.module_id) && parseInt(this.currentModuleId) !== parseInt(moduleId));
      if(!existence) {
        return true;
      }
      return false;
    },

    setEditableData(data) {
      this.brainstormData.id = data.id;
      this.brainstormData.title = data.title;
      this.brainstormData.description = data.description;
      this.brainstormData.project_id = this.$route.params.id;
      this.brainstormData.scenario_id = data.scenario_id;
      this.brainstormData.file = {file_path: data.file?.file_path};
    },

    appends(data) {
      for(let key in data){
        this.formData.append(key, data[key])
      }
    },
    fileUpload(file){
      this.formData.append('image', file)
    },
    updateBrainstorm(callback = () => {}) {
      const self = this;
      self.$Progress.start();
      // this.brainstormData.id = this.selectedCardData.brainstorm.id;
      // this.brainstormData.title = this.selectedCardData.brainstorm.title;
      // this.brainstormData.description = this.selectedCardData.brainstorm.description;
      // this.brainstormData.project_id = self.$route.params.id;
      // this.brainstormData.scenario_id = this.selectedCardData.brainstorm.scenario_id;


      this.appends(this.brainstormData)
      self.$store.dispatch("brainstormv1/updateBrainstorm", this.formData).then(response => {
        self.$store.dispatch("project/getSolidObject", self.$route.params.id)
        self.$store.dispatch("brainstormv1/getBrainstorms", {
          project_id: self.$route.params.id
        })

        this.$Progress.finish();
        if(response && response.data && response.data.status && response.data.data && response.data.data.id) {
          callback()
          this.$emit('close')
          self.closeModal();
          this.$store.dispatch("programmatic/loadScenarios", this.$route.params.id);
          this.$store.getters["brainstormv1/brainstorms"];
          self.$router.push({ name: 'teamwork.mindmap', params: {id: self.$route.params.id, brainstorm_id: response.data.data.id} });

          // self.$router.push({ name: 'brainstorm.show', params: {id: self.$route.params.id, brainstorm_id: response.data.data.id} });
        }
      });
    }
  }
}
</script>

<style scoped>

</style>

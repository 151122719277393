<template>
  <div class="_main_wrapper relative flex">
    <div class="i-left-panel shadow-one">
      <workspace-left-menu />
      <!-- @click="toggleCreateModal(true)" -->
      <button
        v-if="canCreate(project.cans)"
        @click="brainstormCreateModalVisibility = true"
        class="ws-folder-button focus:outline-none hover:bg-secondary-five text-primary-one fs-14 font-poppins fw-600 bg-primary-three shadow-one w-full mt-5 rounded flex items-center justify-center">
        Create Brainstorm
      </button>

      <!-- <div class="ws-folder-box overflow-y-auto scenario-left-box">
        <div class="flex justify-between cursor-pointer text-primary-one opacity-60" @click="toggleDisplay('all')" :class="{'opacity-100': displayMode === 'all'}">
          <h2 class="fs-14 font-poppins fw-600">All Brainstorm</h2>
          <h2 class="fs-14 font-poppins fw-600">{{ brainstorms.length }}</h2>
        </div>
        <div>
          <div class="flex justify-between cursor-pointer text-primary-one opacity-60 mt-8"
               :class="{'opacity-100': displayMode === 'linked'}"
               @click="linkedBrainstormDisplay = !linkedBrainstormDisplay, toggleDisplay('linked')">
            <h2 class="fs-14 font-poppins fw-600">Linked Brainstorm</h2>
            <h2 class="fs-14 font-poppins fw-600">{{ getLinkedCount().length }}/{{ brainstorms.length }}</h2>
          </div>

          <div v-if="linkedBrainstormDisplay">
            <div class="flex justify-between text-primary-one opacity-60 mt-8 ml-3" v-for="(linked, lIndex) in getLinkedCount()" :key="lIndex">
              <h2 class="fs-14 font-poppins fw-600">{{ linked.title }}</h2>
            </div>
          </div>
        </div>
        <div>
          <div class="flex justify-between cursor-pointer text-primary-one opacity-60 mt-8"
               :class="{'opacity-100': displayMode === 'free'}"
               @click="freeBrainstormDisplay = !freeBrainstormDisplay, toggleDisplay('free')">
            <h2 class="fs-14 font-poppins fw-600">Free Brainstorm</h2>
            <h2 class="fs-14 font-poppins fw-600">{{ getFreeCount().length }}/{{ brainstorms.length }}</h2>
          </div>

          <div v-if="freeBrainstormDisplay">
            <div class="flex justify-between text-primary-one opacity-60 mt-8 ml-3" v-for="(free, fIndex) in getFreeCount()" :key="fIndex">
              <h2 class="fs-14 font-poppins fw-600">{{ free.title }}</h2>
            </div>
          </div>
        </div>
      </div> -->

    </div>
    <div class="i-right-panel">
      <div class="ws-heading-box relative flex flex-col pb-8">
        <workspace-menu />
        <h2 class="fs-14 fw-700 font-poppins text-secondary-one absolute page-heading-title">Brainstorm Management</h2>
        <div class="ws-action-box flex items-center justify-between ws-select-dropdown">
          <div class="flex scenario-action-box">
            <div class="flex items-center gap-3">
              <div class="relative i-border-1 border-secondary-two rounded ws-search-width">
                <input
                  type="text"
                  class="focus:outline-none py-2 font-poppins ws-search-box bg-transparent text-secondary-one placeholder-secondary-two"
                  :placeholder="searchField.placeholder"
                  v-model="searchField.text"
                  @click="searchField.placeholder = ''"
                  v-click-outside="outsideSearchInput"
                />
                <div class="mr-2 flex items-center justify-between absolute inset-y-0 right-0 pointer-events-none pr-1">
                  <img
                    class="w-3 h-3"
                    src="/images/icons/search_icon.svg"
                  />
                </div>
              </div>
              <select v-model="scenario_id" class="bg-transparent text-secondary-two fs-14 i-border-1 border-secondary-two py-2 rounded px-4" placeholder="Select scenario">
                <option value="null">Select Scenario</option>
                <option v-for="(scenario, sIndex) in scenarios" :key="sIndex" :value="scenario.id">{{ scenario.title }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="scenario-content-box pl-10 mr-8 mt-8 overflow-y-auto ws-scrollbar">
        <div class="brainstormCards grid grid-cols-3 gap-y-8">
          <DataCard
            v-for="(item, index) in getDisplayData"
            :key="index+Math.floor(Math.random()*1000)"
            :routeObj="{ name: 'teamwork', params: { id: $route.params.id, brainstorm_id: item.id }}"
            :title="item.title"
            subTitle="Active Teamwork for :"
            :category="`Type: ${getType(item)} Brainstorm`"
            :afterSwitch="item.scenario && item.scenario.title ? item.scenario.title : ''"
            :data="[
              {Created: $lodash.format(item.created_at, 'dd/MM/yyyy')},
              {Last_Edited: $lodash.format(item.updated_at, 'dd/MM/yyyy')},
            ]"
            :makeActiveCallBack="() => { setActiveBrainstorm(item) }"
            :editCallBack="() => { toggleEditModal(true, item) }"
            :duplicateCallBack="() => { duplicateBrainstorm(item) }"
            :deleteCallBack="() => { deleteBrainstorm(item.id, index) }"
            :defaultSetCallBack="() => { setDefault(item) }"
            :color="(item.settings) ? item.settings.color&&item.settings.color : defaultColor"
            :isDefault="item.is_default"
            :image="item?.file?.file_path"
          />
        </div>
      </div>
    </div>

    <Modal v-model="brainstormCreateModalVisibility">
      <create-brainstorm @close="() => (brainstormCreateModalVisibility = false)" />
    </Modal>

    <Modal v-model="brainstormEditModalVisibility">
      <edit-brainstorm
        :dModal="eModal"
        :selectedCardData="selectedCardData"
        @close="() => (brainstormEditModalVisibility = false)"
        :currentModuleId="currentModuleId"
      />
    </Modal>
  </div>
</template>

<script setup>
  import Modal from '@/elements/atom/Modal'
  import usePermission from '@/composable/usePermission.js'
  const { canCreate } = usePermission()
</script>

<script>
import axios from 'axios'
import CreateBrainstorm from "../../../components/brainstormv2/CreateBrainstorm";
import EditBrainstorm from "../../../components/brainstormv2/EditBrainstorm";
import {mapGetters} from "vuex";
import scenario from "../programmatic/Scenario";
import DataCard from '@/elements/DataCard.vue'

export default {
  name: "Index",
  components: {EditBrainstorm, CreateBrainstorm, DataCard},
  created() {
    document.title = "Brainstorm Management";
    const self = this;
    self.$store.dispatch("workspace/projectSelection", self.$route.params.id);
    this.$store.dispatch("project/getSolidObject", this.$route.params.id);
    self.$Progress.finish();
    self.$store.dispatch("brainstormv1/getBrainstorms", {
      project_id: self.$route.params.id
    }).then(response => {
      self.$Progress.finish();
    });

    this.$store.dispatch("programmatic/loadScenarios", this.$route.params.id);
  },
  data() {
    return {
      brainstormCreateModalVisibility: false,
      brainstormEditModalVisibility: false,
      searchField: {
        placeholder: 'Search',
        text: ''
      },
      selectedCardData: {},
      linkedBrainstormDisplay: false,
      freeBrainstormDisplay: false,
      cModal: false,
      eModal: false,
      displayMode: 'all',
      hoveredIndex: '',
      currentModuleId: null,
      scenario_id: null,
      defaultColor: '#2b80ad'
    }
  },
  computed: {
    ...mapGetters({
      brainstorms: "brainstormv1/brainstorms",
      scenarios: 'programmatic/scenarios',
      project: 'project/project',
    }),


    getDisplayData() {
      let teamworks = [];
      if (this.displayMode === 'all') {
        teamworks = this.displayAll();
      } else if (this.displayMode === 'linked') {
        teamworks = this.displayLinkedData();
      } else if (this.displayMode === 'free') {
        teamworks = this.displayFreeData();
      }

      if(this.scenario_id) {
        teamworks = teamworks.filter(data => data.scenario_id === this.scenario_id);
      }

      if (this.searchField.text) {
        return teamworks.filter((item) => {
          return this.searchField.text
              .toLowerCase()
              .split(" ")
              .every((v) => item.title.toLowerCase().includes(v) && (!item.deleted_at || false || false));
        });
      }
      return teamworks.filter(item => !item.deleted_at || false || false);
    }



    /* getDisplayData() {
      if (this.displayMode === 'all') {
        return this.displayAll();
      } else if (this.displayMode === 'linked') {
        return this.displayLinkedData();
      } else if (this.displayMode === 'free') {
        return this.displayFreeData();
      }

      if (this.searchField.text) {
        return this.scenarios.filter((item) => {
          return this.searchField.text
              .toLowerCase()
              .split(" ")
              .every((v) => item.title.toLowerCase().includes(v) && (!item.deleted_at || false || false));
        });
      }

      if(this.scenario_id){
        return this.scenarios.filter((item) => this.scenario_id === item.scenario_id)
      }

      return this.scenarios.filter(item => !item.deleted_at || false || false);
    } */
  },
  methods: {

    setDefault(item)
    {
      const self = this;
      const defaultData = this.getDisplayData.filter(item=>item.is_default)
      defaultData.forEach(element => {
        const data = {
          id: element.id,
          project_id: element.project_id,
          scenario_id: element.scenario_id,
          title: element.title,
          is_default: 0,
        }
        axios.post(`brainstorms/${element.id}/update`, data).then(response => {
          self.$store.dispatch("project/getSolidObject", self.$route.params.id)
        })
      })

      const data = {
        id: item.id,
        project_id: item.project_id,
        scenario_id: item.scenario_id,
        title: item.title,
        is_default: 1,
      }

      axios.post(`brainstorms/${item.id}/update`, data).then(response => {
        self.$store.dispatch("project/getSolidObject", self.$route.params.id)
      })
    },

    outsideSearchInput() {
      this.searchField.placeholder = 'Search';
    },
    toggleCreateModal(value) {
      this.cModal = value;
    },
    toggleEditModal(value, item)
    {
      this.eModal = value;
      this.brainstormEditModalVisibility = true
      if(item) {
        this.currentModuleId = item.module_id;
        this.$store.dispatch("brainstormv1/getBrainstorm", item.id);
        this.selectedCardData = item
      } else {
        this.currentModuleId = null;
      }
    },
    toggleDisplay(mode) {
      this.displayMode = mode;
    },
    displayAll() {
      if (this.searchField.text) {
        return this.brainstorms.filter((item) => {
          return this.searchField.text
              .toLowerCase()
              .split(" ")
              .every((v) => item.title.toLowerCase().includes(v));
        });
      }
      return this.brainstorms;
    },
    displayLinkedData() {
      if (this.searchField.text) {
        return this.brainstorms.filter((item) => {
          return this.searchField.text
              .toLowerCase()
              .split(" ")
              .every((v) => item.title.toLowerCase().includes(v) && item.module_id);
        });
      }
      return this.brainstorms.filter(item => item.module_id);
    },
    displayFreeData() {
      if (this.searchField.text) {
        return this.brainstorms.filter((item) => {
          return this.searchField.text
              .toLowerCase()
              .split(" ")
              .every((v) => item.title.toLowerCase().includes(v) && (item.module_id === undefined || !item.module_id || !item.module_id === null));
        });
      }
      return this.brainstorms.filter(item => item.module_id === undefined || !item.module_id || !item.module_id === null);
    },
    getFreeCount() {
      return this.brainstorms.filter(item => item.module_id === undefined || !item.module_id || !item.module_id === null);
    },
    getLinkedCount() {
      return this.brainstorms.filter(item => item.module_id);
    },
    mouseOver(event, index) {
      this.hoveredIndex = index;
    },
    outsideOption() {
      this.hoveredIndex = '';
    },
    setActiveBrainstorm(item) {
      this.$Progress.start();
      this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: item.id}).then(response => {
        if (response) {
          this.$Progress.finish();
        }
      })
    },
    deleteBrainstorm(id, index) {
      let payload = {
        id: id,
        index: index
      };
      this.$Progress.start();
      this.$store.dispatch("brainstormv1/deleteBrainstorm", payload).then(response => {
        this.$Progress.finish();
      })
    },
    duplicateBrainstorm(item) {
      this.$Progress.start();
      this.$store.dispatch("brainstormv1/duplicateBrainstorm", item).then(response => {
        this.$Progress.finish();
      });
    },
    getType(item) {
      if(item.scenario_id) {
        return "Linked Scenario";
      } else if(item.module_id && !item.scenario_id) {
        return "Linked";
      } else {
        return "Free";
      }
    },
    getLinkedInfo(item) {
      if(item.scenario_id) {
        return item.scenario ? item.scenario.title : 'N/A';
      } else if(item.module_id && !item.scenario_id) {
        return item.module ? item.module.title : 'N/A';
      } else {
        return 'N/A';
      }
    }
  }
}
</script>

<style scoped>
  .tools-panel {
    width: 860px;
  }
  @media (max-width: 1850px){
    .brainstormCards {
      grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
      gap: 32px;
      padding-right: 40px;
    }
    .brainstormCards > div{
      max-width: 600px;
      width: 100%;
    }
  }
</style>

<template>
  <div class="p-10">
    <div class="flex justify-between pb-5 i-border-b-1 border-primary-one">
      <h3 class="font-poppins text-primary-one fs-26">Create New Brainstorm </h3>
    </div>

    <div class="i-mt-30">
      <div>
        <h4 class="font-poppins text-secondary-one fs-14">Title</h4>
        <input type="text"
              class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-primary-one rounded"
              v-model="brainstorm.title"
              :placeholder="brainstormPlaceholder.title"
              @click="brainstormPlaceholder.title = ''"
              v-click-outside="outsideTitleInput">
      </div>
      <div class="i-mt-30">
        <h4 class="font-poppins text-secondary-one fs-14">Description</h4>
        <textarea class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 py-2 h-6r i-border-1 border-solid border-primary-one rounded bld-content-area"
                  v-model="brainstorm.description"
                  :placeholder="brainstormPlaceholder.description"
                  @click="brainstormPlaceholder.description = ''"
                  v-click-outside="outsideDescription"></textarea>
      </div>
      <div class="mt-5">
<!--          <div class="flex justify-between">-->
<!--            <h4 class="font-poppins text-secondary-one fs-14">Type / Linked</h4>-->
<!--            <h4 class="font-poppins fs-14 fw-600 cursor-pointer flex items-center" @click="isScenario = !isScenario" :class="{'text-primary-four': isScenario, 'text-secondary-one': !isScenario}">-->
<!--              <div class="checkbox mr-1" :class="{'bg-primary-one border-primary-one': isScenario, 'custom-border': !isScenario}"></div>-->
<!--              <span>Link scenario</span>-->
<!--            </h4>-->
<!--          </div>-->
<!--          <select v-if="!isScenario" class="bg-transparent text-secondary-two fs-14 w-full i-border-1 border-primary-one mt-2 py-2 rounded px-4" v-model="brainstorm.module_id">-->
<!--            <option value="">Free</option>-->
<!--            <option v-for="(module, mIndex) in projectModules" :key="mIndex" :value="module.id">{{ module.title }}</option>-->
<!--          </select>-->
        <div class="flex justify-between">
          <h4 class="font-poppins text-secondary-one fs-14">Scenario</h4>
        </div>
        <select class="bg-transparent text-secondary-two fs-14 w-full i-border-1 border-primary-one mt-2 py-2 rounded px-4" v-model="brainstorm.scenario_id" placeholder="Select scenario">
          <option value="">Select Scenario</option>
          <option v-for="(scenario, sIndex) in scenarios" :key="sIndex" :value="scenario.id">{{ scenario.title }}</option>
        </select>
      </div>
      <div class="i-mt-30 flex gap-6 items-center">
          <label class=" flex gap-3 items-center cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3zM8 13h2.55v3h2.9v-3H16l-4-4z"/></svg>
              <span>File Upload</span>
              <input @input="(e)=>{onChange(e), fileUpload(file)}" hidden type="file" accept="image/x-png,image/jpeg" />
          </label>
          <img v-if="previewURL" class="w-10 h-8 object-cover" :src="previewURL" />
      </div>

      <div class="flex i-mt-30">
        <button class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one" @click="createBrainstorm(() => previewURL = '')" :disabled="disableClick">Create</button>
        <button class="focus:outline-none fs-14 fw-600 font-poppins i-pl-35 i-pr-35 i-border-1 border-secondary-one ml-5 rounded text-primary-one h-10 w-40" @click="closeModal(()=>previewURL='')" :disabled="disableClick">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useFile } from '@/composable/useFile.js'

  // let emit = defineEmits(['close'])
  let { onChange, previewURL, file } = useFile()
  const closeModal = (callback = () => {}) => {
    // this.$modal.hide('brainstorm-create-modal');
    emit("close")
    callback()
  }
</script>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CreateBrainstorm",
  props: ['dModal'],
  created() {
    // this.$store.dispatch("programmatic/getModules", this.$route.params.id);
    this.$store.dispatch("programmatic/loadScenarios", this.$route.params.id);
  },
  data() {
    return {
      disableClick: false,
      isScenario: false,
      brainstorm: {
        title: '',
        description: '',
        module_id: '',
        scenario_id: ''
      },
      brainstormPlaceholder: {
        title: 'Type title here',
        description: 'Your description here'
      },
      formData: new FormData()
    }
  },
  computed: {
    ...mapGetters({
      loading: "GET_LOADING_STATE",
      projectModules: "programmatic/projectModules",
      scenarios: 'programmatic/scenarios',
    })
  },
  watch: {
    dModal: function (val) {
      if(val) {
        this.$modal.show('brainstorm-create-modal');
      }
    }
  },
  methods: {
    outsideTitleInput() {
      this.brainstormPlaceholder.title = 'Type title here';
    },
    outsideDescription() {
      this.brainstormPlaceholder.description = 'Type description here';
    },
    appends(data) {
        for(let key in data){
            this.formData.append(key, data[key])
        }
    },
    fileUpload(file){
      this.brainstorm.image = file
    },
    createBrainstorm(callback=()=>{}) {
      const self = this;
      if(!this.brainstorm.scenario_id) {
        alert('Please select scenario');
        return;
      }
      self.$Progress.start();
      this.brainstorm.project_id = self.$route.params.id;

      this.appends(this.brainstorm)
      self.disableClick = true;
      self.$store.dispatch("brainstormv1/storeBrainstorm", this.formData).then(response => {
        self.disableClick = false;
        self.$store.dispatch("project/getSolidObject", self.$route.params.id)
        this.$Progress.finish();
        if(response && response.data && response.data.status && response.data.data && response.data.data.id)
        {
          callback()
          this.$emit('close')
          self.brainstorm = {
            title: '',
            description: '',
            module_id: '',
            scenario_id: ''
          };
          self.$router.push({ name: 'teamwork.mindmap', params: {id: self.$route.params.id, brainstorm_id: response.data.data.id} });
        }
      });
    }
  }
}
</script>

<style scoped>
.h-6r {
  height: 6rem;
}
.checkbox {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 2px;
}
.custom-border {
  border: 2px solid #8d8d8d;
}
</style>
